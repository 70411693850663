import gjs from "grapesjs";
import axios from "axios";

import * as backendModule from "../../../../modules/backendModule";

function script() {

  eval(`(()=>{
    let selectedForm = this.getAttribute('selectForm');
    let scaleLeadForm = document.querySelector(".scalelead-form");

    const scaleLeadFormBtn = scaleLeadForm.querySelector("button");
    const scaleLeadFormCurLanguage = scaleLeadForm.getAttribute("language");
    let scaleLeadFormDataToken = scaleLeadForm.getAttribute("data-token");
    const scaleLeadFormDataTokenSelect = scaleLeadForm.getAttribute("data-tokenSelect");
    const scaleLeadFormDataDomain = scaleLeadForm.getAttribute("data-domain");
    const scaleLeadFormDataThankYou = scaleLeadForm.getAttribute("data-thankyou");
    const scaleLeadFormDataArticle = scaleLeadForm.getAttribute("data-article");

    let gifts = document.querySelectorAll(".scalecms-closedFormMedical-body-box");
    let sPopup = document.querySelector(".present__form__sPopup__medical");
    let sPopupBtn = document.querySelector(".present__form__sPopup__medical__wrap__btn");
    let final = document.querySelector('.scalecms-closedFormMedical-final');

    const productName = this.getAttribute('productName');
    const oldPrice = this.getAttribute('oldPrice');

    if(productName){
      let productNamesParas = document.querySelectorAll(".proizvod-name");
      for(let elem of productNamesParas){
        elem.textContent = productName;
      }
    }
    if(oldPrice){
      let oldPriceParas = document.querySelectorAll(".old-price");
      for(let elem of oldPriceParas){
        elem.textContent = oldPrice;
      }
    }

    const handleGifts = (e) => {
      for (let item of gifts) {
        if(item === e.target){
          e.target.src = "https://backend.scale-cms.com:5000/staticContent/images/d44ba464-8ef4-4870-b6c6-ab3ed30676e4-1708076563506.png";
          e.target.style.pointerEvents = "none";
        }else{
          item.src = "https://backend.scale-cms.com:5000/staticContent/images/ee78085e-9b8f-4591-a853-9ac9e8e73be1-1708071598890.png";
          item.style.pointerEvents = "none";
        }
      };
      
      setTimeout(() => {
        sPopup.style.display = "flex";
        sPopup.style.zIndex = "3";
      }, 1000)
      
    };

    for (let item of gifts) {
      item.addEventListener("click", (e) => handleGifts(e));
    };

    if (sPopupBtn) {
      sPopupBtn.addEventListener("click", () => {
        sPopup.style.display = "none";
        final.style.display = "flex";
        // countdown();
      });
    };
  })()`)

  
}

/**
 * @param {gjs.Editor} editor
 */
export default (editor, data, trackData) => {

  editor.on('component:add', (component) => {
    // Check if there are existing components with the class 'formInserted'
    const existingForms = editor.Canvas.getDocument().querySelectorAll('.formInserted');
  
    if (existingForms.length > 1) {
      // Remove the newly added component from the editor
      component.remove();
  
      console.log("Existing forms found: ", existingForms.length);
    }
  });

  const findComponentByType = (components, type) => {
    for (let component of components) {
      if (component.get('type') === type) {
        return component;
      }
      if (component.models && component.models.length > 0) {
        let nestedComponent = findComponentByType(component.components, type);
        if (nestedComponent) {
          return nestedComponent;
        }
      }else{
        if(typeof(component.components) === "function"){
          let nestedComponent = findComponentByType(component.components(), type);
          if (nestedComponent) {
            return nestedComponent;
          }
        }
      }
    }
    return null;
  }

  function findForKeyInAttributes(object, changed) {
    // console.log(inner, "OBJECTS ARRAY --- ", object)
    if(!changed) return

    if (object?.attributes && object?.attributes?.for || object?.attributes && object?.attributes?.attributes?.for) {
      switch (object?.attributes?.attributes?.for) {
        case 'ProizvodName':
          let currElem = data.find(el => el.UniqueToken === changed["data-offers"]);
          if(!currElem) break;
          let trackElem = trackData.find(el => el.ScaleleadOfferID === currElem.BoundOffer);
          if(!trackElem) break;
          object.components(`${trackElem.OfferName}`);
          break;
        case 'OldPrice':
          switch (changed?.country) {
            case "rs":
              object.components('7200 RSD');
              return;
            case "hr":
              object.components('79.80 €');
              return;
            case "ba":
              object.components('119.80 KM');
              return;
            case "me":
              object.components('79.80 €');
              return;
            case "es":
              object.components('79.80 €');
              return;
            default:
              break;
          };
          break;
      
        default:
          break;
      }
    }

    if (object?.attributes?.components?.models) {
      // console.log("COMPONENTS --->> MODELS")
      object?.attributes?.components?.models.forEach(elem => {
        findForKeyInAttributes(elem, changed)
      })
    }
  }

  editor.Components.addType('scalecms-closedFormMedical', {
    isComponent: e => e?.classList?.contains("scalecms-closedFormMedical"),
    model: {
      init() {
        this.on("change", e => {
          let formComponent = findComponentByType(editor.DomComponents.getComponents(), 'scalelead-form_cmp');
          if (e?.attributes?.attributes?.["data-offers"]) {
            formComponent.attributes.attributes["data-tokenselect"] = e?.attributes?.attributes?.["data-offers"];
            let curOffer = data.find(o => o.UniqueToken === e?.attributes?.attributes?.["data-offers"]);
            if (curOffer) {
              formComponent.attributes.attributes["data-domain"] = curOffer.Domain;
            }
          }

          formComponent.attributes.attributes["data-tokenselect"] = e?.attributes?.attributes?.["data-offers"];
          formComponent.attributes.attributes["data-article"] = e?.attributes?.attributes?.["data-suffix"];

          this.get("components").each(child => {
            findForKeyInAttributes(child, e.changed.attributes)
          });
        });
      },
      defaults: {
        script,
        type: "default",
        traits(component) {

          let offerData = data.map(o => {
            return {
              id: o.UniqueToken,
              name: o.FriendlyName ? `${o.FriendlyName} (${o.TakenOfferName})` : o.TakenOfferName
            };
          }).sort((a, b) => {
            return String(a.name).localeCompare(b.name);
          });
          offerData = [{ id: null, name: "Not selected" }, ...offerData];
          return [
            { type: "button", text: "Open form", full: true, command: editor => {
              let el = editor.getSelected().getEl();
              let finalForm = el.querySelector('.scalecms-closedFormMedical-final');
              finalForm.style.display = "flex";
            } },
            { type: "button", text: "Close form", full: true, command: editor => {
              let el = editor.getSelected().getEl();
              let finalForm = el.querySelector('.scalecms-closedFormMedical-final');
              finalForm.style.display = "none";
            } },
            {
              type: 'select',
              label: 'Select an offer',
              name: 'data-offers',
              options: offerData,
            },
            {
              name: "country", type: "select", options: [
                { id: "custom", name: "Custom" },
                { id: "ba", name: "Bosna", selected: true },
                { id: "rs", name: "Srbija" },
                { id: "hr", name: "Hrvatska" },
                { id: "me", name: "Crna Gora" },
                { id: "es", name: "Španija" }
              ], value: "ba"
            },
            { name: "data-suffix", label: "Lead UID (Name suffix in CRM)", placeholder: "CRM, LV...", value: "" },
          ]
        },
        attributes: {
          class: "scalecms-closedFormMedical scroll-target"
        },
        styles: `
          .scalecms-closedFormMedical{
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: 650px;
            -webkit-box-shadow: 0px 0px 5px 3px rgba(34,34,34,0.75);
            background-color: white;
            position: relative;
            padding: 20px;
            margin: 0 auto;
            scroll-margin: 20px;
            z-index: 3;
          }
          .scalecms-closedFormMedical-head{
            color: #fff;
            text-align: center;
            padding: 10px 0;
            border-radius: 8px;
            font-size: 22px;
            background-color: #3a4752;
          }
          .scalecms-closedFormMedical-body{
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            width: 100%;
          }
          .scalecms-closedFormMedical-body-box{
            width: 100%;
            height: auto;
            cursor: pointer;
            transition: transform 0.3s ease;
          }
          .scalecms-closedFormMedical-body-box:hover {
            transform: perspective(400px) rotateX(30deg);
          }
          .present__form__sPopup__medical{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: none;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            background-color: rgba(0, 0, 0, 0.2333);
            z-index: 0;
          }
          .present__form__sPopup__medical__wrap{
            padding: 20px;
            width: 100%;
            max-width: 300px;
            background-color: #fff;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
          }
          .present__form__sPopup__medical__wrap__head{
            color: red;
            font-weight: bold;
            font-size: 26px;
            margin-bottom: 30px;
          }
          .present__form__sPopup__medical__wrap__msg{
            font-size: 22px;
            font-weight: 500;
            width: 100%;
            line-height: 20px;
            z-index: 2;
            text-align: center;
            gap: 6px;
            margin-bottom: 10px;
            display: flex;
            flex-direction: column;
            color: #7FB3D5;
          }
          .present__form__sPopup__medical__wrap__msg2{
            text-align: center;
            font-size: 22px;
            margin-bottom: 30px;
            font-weight: 700;
            color: #7FB3D5;
          }
          .present__form__sPopup__medical__wrap__btn{
            width: 100%;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-size: 16px;
            background-color: rgb(126, 187, 35);
            border-radius: 5px;
            cursor: pointer;
            font-weight: 700;
          }
          .scalecms-closedFormMedical-final{
            max-width: 100%;
            display: none;
            flex-direction: column;
            align-items: center;
            padding: 20px;
          }
          .scalecms-closedFormMedical-final-head{
            display: flex;
            gap: 8px;
            margin-bottom: 15px;
          }
          .scalecms-closedFormMedical-final-head-text{
            font-size: 24px;
            text-align: center;
            line-height: 25px;
            display: inline-block;
          }
          .scalecms-closedFormMedical-final-head-product{
            font-size: 24px;
            text-align: center;
            line-height: 25px;
            font-weight: 700;
            display: inline-block;
          }
          .scalecms-closedFormMedical-final-oldPrice{
            color: red;
            text-decoration: line-through;
            font-size: 24px;
            text-align: center;
            line-height: 25px;
            font-weight: 700;
            margin-bottom: 15px;
          }
          .scalecms-closedFormMedical-final-newPrice{
            font-size: 24px;
            text-align: center;
            line-height: 25px;
            color: green;
            font-weight: 700;
            margin-bottom: 18px;
          }


          

          .scalecms-closedFormMedical .scalecrm-form{
            width: 100%;
            padding: 0px;
          }
          .scalecms-closedFormMedical .scalecrm-form > label{
            display: none;
          }
          .scalecms-closedFormMedical .scalecrm-form > input{
            border: 1px solid #ddd;
            padding: .625em;
            width: 100%;
            box-sizing: border-box;
            margin-bottom: 20px;
            min-height: 50px;
            border-radius: 10px;
          }
          .scalecms-closedFormMedical .scalecrm-form > button{
            width: 100%;
            height: 50px;
            background: #FF4742;
            border: 1px solid #FF4742;
            border-radius: 6px;
            box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
            box-sizing: border-box;
            color: #FFFFFF;
            cursor: pointer;
            display: inline-block;
            font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
            font-size: 16px;
            font-weight: 800;
            line-height: 16px;
            min-height: 40px;
            outline: 0;
            padding: 12px 14px;
          }
          .scalecms-closedFormMedical .scalecrm-form > button:hover{
            background-color: #d14945;
          }

          .scalecms-closedFormMedical .scalelead-form{
            width: 100%;
            padding: 0px;
          }
          .scalecms-closedFormMedical .scalelead-form > label{
            display: none;
          }
          .scalecms-closedFormMedical .scalelead-form > input{
            border: 1px solid #ddd;
            padding: .625em;
            width: 100%;
            box-sizing: border-box;
            margin-bottom: 20px;
            min-height: 50px;
            border-radius: 10px;
          }
          .scalecms-closedFormMedical .scalelead-form > button{
            width: 100%;
            height: 50px;
            background: #FF4742;
            border: 1px solid #FF4742;
            border-radius: 6px;
            box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
            box-sizing: border-box;
            color: #FFFFFF;
            cursor: pointer;
            display: inline-block;
            font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
            font-size: 16px;
            font-weight: 800;
            line-height: 16px;
            min-height: 40px;
            outline: 0;
            padding: 12px 14px;
          }
          .scalecms-closedFormMedical .scalelead-form > button:hover{
            background-color: #d14945;
          }

        `,
        components: [
          {
            type: 'text',
            attributes: { class: 'scalecms-closedFormMedical-head formInserted' },
            components: 'IZABERITE PAKET S MOGUĆIM POPUSTOM OD 100%'
          },
          {
            tagName: 'div',
            attributes: { class: 'scalecms-closedFormMedical-body' },
            components: [
              {
                tagName: 'img',
                attributes: { class: 'scalecms-closedFormMedical-body-box giftClosed', src: 'https://backend.scale-cms.com:5000/staticContent/images/b8fcf688-65ed-4896-9629-a956feda203f-1708071608566.png' }
              },
              {
                tagName: 'img',
                attributes: { class: 'scalecms-closedFormMedical-body-box giftClosed', src: 'https://backend.scale-cms.com:5000/staticContent/images/b8fcf688-65ed-4896-9629-a956feda203f-1708071608566.png' }
              },
              {
                tagName: 'img',
                attributes: { class: 'scalecms-closedFormMedical-body-box giftClosed', src: 'https://backend.scale-cms.com:5000/staticContent/images/b8fcf688-65ed-4896-9629-a956feda203f-1708071608566.png' }
              }
            ]
          },
          {
            tagName: 'div',
            attributes: { class: 'scalecms-closedFormMedical-final'},
            components: [
              {
                type: 'div',
                attributes: { class: 'scalecms-closedFormMedical-final-head' },
                components: [
                  {
                    type: 'text',
                    attributes: { class: 'scalecms-closedFormMedical-final-head-text' },
                    components: 'Možete preuzeti '
                  },
                  {
                    type: 'text',
                    attributes: { for: "ProizvodName", class: 'scalecms-closedFormMedical-final-head-product proizvod-name' },
                    components: 'PRODUKT'
                  },
                ]
              },
              {
                type: 'text',
                attributes: { for: "OldPrice", class: 'scalecms-closedFormMedical-final-oldPrice old-price' },
                components: '7200 RSD'
              },
              {
                type: 'text',
                attributes: { class: 'scalecms-closedFormMedical-final-newPrice' },
                components: 'BESPLATNO'
              },
              {
                type: 'scalelead-form_cmp',
                attributes: { class: 'scalelead-form', 
                  'data-token': '', 
                  'data-tokenselect': '', 
                  'data-domain': '' ,
                  'data-article': '',
                  'language': '',
                  'data-custombuttontext': ''
                }
              }
            ]
          },
          {
            type: 'div',
            attributes: {class: 'present__form__sPopup__medical'},
            components: [
              {
                type: 'div',
                attributes: {class: 'present__form__sPopup__medical__wrap'},
                components: [
                  {
                    type: 'text',
                    attributes: { class: 'present__form__sPopup__medical__wrap__head' },
                    components: 'ČESTITAMO!'
                  },
                  {
                    type: 'text',
                    attributes: { class: 'present__form__sPopup__medical__wrap__msg' },
                    components: [
                      {
                        type: 'text',
                        attributes: { class: '' },
                        components: 'Osvojili ste'
                      },
                      {
                        type: 'text',
                        attributes: { for: "ProizvodName", class: 'proizvod-name' },
                        components: 'proizvod'
                      },
                      {
                        type: 'text',
                        attributes: { class: '' },
                        components: 'sa popustom:'
                      },
                    ]
                  },
                  {
                    type: 'text',
                    attributes: { class: 'present__form__sPopup__medical__wrap__msg2' },
                    components: '100%'
                  },
                  {
                    type: 'button',
                    attributes: { class: 'present__form__sPopup__medical__wrap__btn' },
                    components: 'OK'
                  }
                ]
              }
            ]
          }
        ]
      }
    }
  });


  editor.BlockManager.add('scalecms-closedFormMedical-blk', {
    label: 'Closed Form Medical',
    content: {
      type: "scalecms-closedFormMedical",
    },
    category: {
      label: 'Closed Forms'
    },
    media: `
    <svg fill="#ffffff" viewBox="0 0 32 32" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;" version="1.1" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:serif="http://www.serif.com/" xmlns:xlink="http://www.w3.org/1999/xlink" stroke="#ffffff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M15,16.993l-2.007,0c-0.551,0 -1,0.449 -1,1c0,0.552 0.449,1 1,1l2.007,0l-0,2.007c-0,0.552 0.448,1 1,1c0.552,0 1,-0.448 1,-1l-0,-2.007l2.007,0c0.551,0 1,-0.448 1,-1c-0,-0.551 -0.449,-1 -1,-1l-2.007,0l-0,-2.006c-0,-0.552 -0.448,-1 -1,-1c-0.552,-0 -1,0.448 -1,1l-0,2.006Z"></path><path d="M11,6l-6,0c-0.796,-0 -1.559,0.316 -2.121,0.879c-0.563,0.562 -0.879,1.325 -0.879,2.121c0,3.463 0,10.537 0,14c-0,0.796 0.316,1.559 0.879,2.121c0.562,0.563 1.325,0.879 2.121,0.879c4.841,0 17.147,0 21.988,0c0.795,0 1.558,-0.316 2.121,-0.879c0.563,-0.562 0.879,-1.325 0.879,-2.121l-0,-14c-0,-0.796 -0.316,-1.559 -0.879,-2.121c-0.563,-0.563 -1.326,-0.879 -2.121,-0.879l-5.988,0l0,-1c-0,-1.657 -1.343,-3 -3,-3l-4,0c-1.657,0 -3,1.343 -3,3l-0,1Zm16.988,6l-0,11c-0,0.265 -0.106,0.52 -0.293,0.707c-0.188,0.188 -0.442,0.293 -0.707,0.293l-21.988,-0c-0.265,0 -0.52,-0.105 -0.707,-0.293c-0.188,-0.187 -0.293,-0.442 -0.293,-0.707l-0,-11l23.988,0Zm-0,-2l-23.988,0l-0,-1c-0,-0.265 0.105,-0.52 0.293,-0.707c0.187,-0.188 0.442,-0.293 0.707,-0.293l21.988,0c0.265,-0 0.519,0.105 0.707,0.293c0.187,0.187 0.293,0.442 0.293,0.707l-0,1Zm-8.988,-4l0,-1c-0,-0.552 -0.448,-1 -1,-1c-0,0 -4,0 -4,0c-0.552,0 -1,0.448 -1,1l-0,1l6,0Z"></path></g></svg>
    `,
    attributes: {
      title: 'ClosedForm Medical',
    },
    select: true
  });
};

// https://backend.scale-cms.com:5000/staticContent/images/67d407c5-3f31-4ef7-b767-bf1fbfbb7583-1708071583625.png   - opened 50% discount
// https://backend.scale-cms.com:5000/staticContent/images/b8fcf688-65ed-4896-9629-a956feda203f-1708071608566.png   -  closed
// https://backend.scale-cms.com:5000/staticContent/images/ee78085e-9b8f-4591-a853-9ac9e8e73be1-1708071598890.png   - opened prazan