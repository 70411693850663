import gjs from "grapesjs";

function script() {

  let selectedForm = this.getAttribute('select-form');
  let scaleLeadForm = document.querySelector(".scalelead-form");

  eval(``)
};

/**
 * @param {gjs.Editor} editor
 */
export default (editor, data, trackData) => {

  editor.on('component:add', (component) => {
    // Check if there are existing components with the class 'formInserted'
    const existingForms = editor.Canvas.getDocument().querySelectorAll('.formInserted');
  
    if (existingForms.length > 1) {
      // Remove the newly added component from the editor
      component.remove();
  
      console.log("Existing forms found: ", existingForms.length);
    }
  });

  const findComponentByType = (components, type) => {
    for (let component of components) {
      if (component.get('type') === type) {
        return component;
      }
      if (component.models && component.models.length > 0) {
        let nestedComponent = findComponentByType(component.components, type);
        if (nestedComponent) {
          return nestedComponent;
        }
      }else{
        if(typeof(component.components) === "function"){
          let nestedComponent = findComponentByType(component.components(), type);
          if (nestedComponent) {
            return nestedComponent;
          }
        }
      }
    }
    return null;
  }

  function findForKeyInAttributes(object, changed) {
    // console.log(inner, "OBJECTS ARRAY --- ", object)
    if(!changed) return

    if (object?.attributes && object?.attributes?.for || object?.attributes && object?.attributes?.attributes?.for) {
      switch (object?.attributes?.attributes?.for) {
        case 'ProizvodName':
          let currElem = data.find(el => el.UniqueToken === changed["data-offers"]);
          if(!currElem) break;
          let trackElem = trackData.find(el => el.ScaleleadOfferID === currElem.BoundOffer);
          if(!trackElem) break;
          object.components(`${trackElem.OfferName}`);
          break;
        case 'OldPrice':
          switch (changed?.country) {
            case "rs":
              object.components('7200 RSD');
              return;
            case "hr":
              object.components('79.80 €');
              return;
            case "ba":
              object.components('119.80 KM');
              return;
            case "me":
              object.components('79.80 €');
              return;
            case "es":
              object.components('79.80 €');
              return;
            default:
              break;
          };
          break;
      
        default:
          break;
      }
    }

    if (object?.attributes?.components?.models) {
      object?.attributes?.components?.models.forEach(elem => {
        findForKeyInAttributes(elem, changed)
      })
    }
  }

  editor.Components.addType('open-form4_cmp', {
    isComponent: e => e?.classList?.contains("open-form4"),
    model: {
      init() {
        this.on("change", e => {
          let formComponent = findComponentByType(editor.DomComponents.getComponents(), 'scalelead-form_cmp');
          if (e?.attributes?.attributes?.["data-offers"]) {
            formComponent.attributes.attributes["data-tokenselect"] = e?.attributes?.attributes?.["data-offers"];
            let curOffer = data.find(o => o.UniqueToken === e?.attributes?.attributes?.["data-offers"]);
            if (curOffer) {
              formComponent.attributes.attributes["data-domain"] = curOffer.Domain;
            }
          }

          formComponent.attributes.attributes["data-tokenselect"] = e?.attributes?.attributes?.["data-offers"];
          formComponent.attributes.attributes["data-article"] = e?.attributes?.attributes?.["data-suffix"];

          this.get("components").each(child => {
            findForKeyInAttributes(child, e.changed.attributes)
          });
        });
      },
      defaults: {
        type: "default",
        traits(component) {

          let offerData = data.map(o => {
            return {
              id: o.UniqueToken,
              name: o.FriendlyName ? `${o.FriendlyName} (${o.TakenOfferName})` : o.TakenOfferName
            };
          }).sort((a, b) => {
            return String(a.name).localeCompare(b.name);
          });
          offerData = [{ id: null, name: "Not selected" }, ...offerData];
          return [
            {
              type: 'select',
              label: 'Select an offer',
              name: 'data-offers',
              options: offerData,
            },
            {
              name: "country", type: "select", options: [
                { id: "custom", name: "Custom" },
                { id: "ba", name: "Bosna", selected: true },
                { id: "rs", name: "Srbija" },
                { id: "hr", name: "Hrvatska" },
                { id: "me", name: "Crna Gora" },
                { id: "es", name: "Španija" }
              ], value: "ba"
            },
            { name: "data-suffix", label: "Lead UID (Name suffix in CRM)", placeholder: "CRM, LV...", value: "" },
          ]
        },
        attributes: {
          class: "open-form4"
        },
        script,
        styles: `
          .open-form4{
            margin: 0 auto;
            border: 1px solid #cdcdcd;
            padding: 20px 10px;
            border-radius: 10px;
            width: 100%;
            max-width: 1004px;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
          }
          .open-form4-left{
            float: left;
            max-width: 100%;
            position: relative;
            text-align: center;
            font-family: 'Arial', sans-serif;
          }
          .open-form4-left-inner{
            position: absolute;
            font-family: Arial, sans-serif;
            left: 70px;
            top: 0px;
            color: rgb(0, 0, 0);
            box-shadow: rgba(0, 0, 0, 0.8) 0px 3px 30px;
            font-size: 19px;
            font-weight: bold;
            line-height: 1.3;
            transform: rotateZ(-7deg);
            text-align: center !important;
            padding: 10px 15px;
            margin: 0px auto;
            background: linear-gradient(to right, rgb(238, 165, 19) 0%, rgb(253, 237, 19) 100%);
            border-width: 2px;
            border-style: solid;
            border-color: rgb(255, 255, 255);
            border-image: initial;
            border-radius: 19%;
          }
          .open-form4-left-inner-povoljno{
            font-family: Arial, sans-serif;
            color: rgb(0, 0, 0);
            font-size: 18px;
            font-weight: bold;
            line-height: 1.3;
            text-align: center;
          }
          .open-form4-left-inner-oldPrice{
            display: flex;
            font-family: Arial, sans-serif;
            color: rgb(0, 0, 0);
            font-size: 19px;
            font-weight: bold;
            justify-content: center;
            text-decoration: line-through;
          }
          .open-form4-left-inner-newPrice{
            border-bottom: 2px solid #d31812;
            font-size: 24px;
            line-height: 15px;
            margin: 0 auto;
            font-family: Arial, sans-serif;
            color: rgb(0, 0, 0);
            text-align: center;
            padding-bottom: 2px;
          }
          .open-form4-img{
            width: 100%;
            max-width: 255px;
            height: auto;
          }

          .open-form4-right{
            display: flex;
            flex-direction: column;
            gap: 24px;
            min-width: 280px;
          }
          .open-form4-right-ostalo{
            padding: 10px 15px;
            border: 1px solid #cdcdcd;
            box-sizing: border-box;
            margin-bottom: 20px;
            min-height: 50px;
            border-radius: 10px;
            background-color: #f0f8ff;
            font-size: 18px;
          }
          .open-form4-right-ostalo-styled{
            color: white;
            font-weight: 600;
            border: 1px solid #f50000;
            padding: 2px;
            background: red;
            border-radius: 7px;
            margin-left: 5px;
            margin-right: 5px;
            width: 30px;
            display: inline-block;
            text-align: center;
          }

          

          .open-form4 .scalecrm-form{
            width: 100%;
            padding: 0px;
          }
          .open-form4 .scalecrm-form > label{
            display: none;
          }
          .open-form4 .scalecrm-form > input{
            border: 1px solid #ddd;
            padding: .625em;
            width: 100%;
            box-sizing: border-box;
            margin-bottom: 20px;
            min-height: 50px;
            border-radius: 10px;
          }
          .open-form4 .scalecrm-form > button{
            width: 100%;
            height: 50px;
            background: #FF4742;
            border: 1px solid #FF4742;
            border-radius: 6px;
            box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
            box-sizing: border-box;
            color: #FFFFFF;
            cursor: pointer;
            display: inline-block;
            font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
            font-size: 16px;
            font-weight: 800;
            line-height: 16px;
            min-height: 40px;
            outline: 0;
            padding: 12px 14px;
          }
          .open-form4 .scalecrm-form > button:hover{
            background-color: #d14945;
          }

          .open-form4 .scalelead-form{
            width: 100%;
            padding: 0px;
          }
          .open-form4 .scalelead-form > label{
            display: none;
          }
          .open-form4 .scalelead-form > input{
            border: 1px solid #ddd;
            padding: .625em;
            width: 100%;
            box-sizing: border-box;
            margin-bottom: 20px;
            min-height: 50px;
            border-radius: 10px;
          }
          .open-form4 .scalelead-form > button{
            width: 100%;
            height: 50px;
            background: #FF4742;
            border: 1px solid #FF4742;
            border-radius: 6px;
            box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
            box-sizing: border-box;
            color: #FFFFFF;
            cursor: pointer;
            display: inline-block;
            font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
            font-size: 16px;
            font-weight: 800;
            line-height: 16px;
            min-height: 40px;
            outline: 0;
            padding: 12px 14px;
          }
          .open-form4 .scalelead-form > button:hover{
            background-color: #d14945;
          }


          @media screen and (max-width: 700px){
            .open-form4{
              flex-direction: column;
            }
          }
        `,
        components: [
          
          {
            tagName: 'div',
            attributes: { class: 'open-form4-left formInserted' },
            components: [
              {
                tagName: 'div',
                attributes: { class: 'open-form4-left-inner' },
                components: [
                  {
                    type: 'text',
                    attributes: { class: 'open-form4-left-inner-povoljno' },
                    components: 'Povoljno!'
                  },
                  {
                    type: 'text',
                    attributes: { for: "OldPrice", class: 'open-form4-left-inner-oldPrice' },
                    components: '119.80 KM'
                  },
                  {
                    type: 'text',
                    attributes: { class: 'open-form4-left-inner-newPrice' },
                    components: 'BESPLATNO'
                  },
                ]
              },
              {
                type: 'image',
                attributes: { class: 'open-form4-img' },
              },
            ]
          },
          {
            tagName: 'div',
            attributes: { class: 'open-form4-right' },
            components: [
              {
                type: 'text',
                attributes: { class: 'open-form4-right-ostalo' },
                components: [
                  'Ostalo je još ',
                  {
                    type: 'text',
                    attributes: { class: 'open-form4-right-ostalo-styled' },
                    components: '19'
                  },
                  ' komada'
                ]
              },
              {
                type: 'scalelead-form_cmp',
                attributes: { class: 'scalelead-form', 
                  'data-token': '', 
                  'data-tokenselect': '', 
                  'data-domain': '' ,
                  'data-article': '',
                  'language': '',
                  'data-custombuttontext': ''
                }
              }
            ]
          },

        ]
      }
    }
  });


  editor.BlockManager.add('open-form4_blk', {
    label: 'Open form 4',
    content: {
      type: "open-form4_cmp",
    },
    category: {
      label: 'Open Forms'
    },
    media: "<img src='/images/openForm4.svg' style='width: 100%; height: auto; filter: invert(1) hue-rotate(180deg);' />",
    attributes: {
      title: 'Insert an Open Form block',
    },
    select: true
  });
};