import gjs from "grapesjs";

function script() {

  let scaleLeadForm = document.querySelector(".scalelead-form");

  eval(``)
};

/**
 * @param {gjs.Editor} editor
 */
export default (editor, data, trackData) => {

  editor.on('component:add', (component) => {
    // Check if there are existing components with the class 'formInserted'
    const existingForms = editor.Canvas.getDocument().querySelectorAll('.formInserted');
  
    if (existingForms.length > 1) {
      // Remove the newly added component from the editor
      component.remove();
  
      console.log("Existing forms found: ", existingForms.length);
    }
  });

  const findComponentByType = (components, type) => {
    for (let component of components) {
      if (component.get('type') === type) {
        return component;
      }
      if (component.models && component.models.length > 0) {
        let nestedComponent = findComponentByType(component.components, type);
        if (nestedComponent) {
          return nestedComponent;
        }
      }else{
        if(typeof(component.components) === "function"){
          let nestedComponent = findComponentByType(component.components(), type);
          if (nestedComponent) {
            return nestedComponent;
          }
        }
      }
    }
    return null;
  }

  function findForKeyInAttributes(object, changed) {
    // console.log(inner, "OBJECTS ARRAY --- ", object)
    if(!changed) return

    if (object?.attributes && object?.attributes?.for || object?.attributes && object?.attributes?.attributes?.for) {
      switch (object?.attributes?.attributes?.for) {
        case 'ProizvodName':
          let currElem = data.find(el => el.UniqueToken === changed["data-offers"]);
          if(!currElem) break;
          let trackElem = trackData.find(el => el.ScaleleadOfferID === currElem.BoundOffer);
          if(!trackElem) break;
          object.components(`${trackElem.OfferName}`);
          break;
        case 'OldPrice':
          switch (changed?.country) {
            case "rs":
              object.components('7200 RSD');
              return;
            case "hr":
              object.components('79.80 €');
              return;
            case "ba":
              object.components('119.80 KM');
              return;
            case "me":
              object.components('79.80 €');
              return;
            case "es":
              object.components('79.80 €');
              return;
            default:
              break;
          };
          break;
      
        default:
          break;
      }
    }

    if (object?.attributes?.components?.models) {
      object?.attributes?.components?.models.forEach(elem => {
        findForKeyInAttributes(elem, changed)
      })
    }
  }

  editor.Components.addType('open-form6_cmp', {
    isComponent: e => e?.classList?.contains("open-form6"),
    model: {
      init() {
        this.on("change", e => {
          let formComponent = findComponentByType(editor.DomComponents.getComponents(), 'scalelead-form_cmp');
          if (e?.attributes?.attributes?.["data-offers"]) {
            formComponent.attributes.attributes["data-tokenselect"] = e?.attributes?.attributes?.["data-offers"];
            let curOffer = data.find(o => o.UniqueToken === e?.attributes?.attributes?.["data-offers"]);
            if (curOffer) {
              formComponent.attributes.attributes["data-domain"] = curOffer.Domain;
            }
          }

          formComponent.attributes.attributes["data-tokenselect"] = e?.attributes?.attributes?.["data-offers"];
          formComponent.attributes.attributes["data-article"] = e?.attributes?.attributes?.["data-suffix"];

          this.get("components").each(child => {
            findForKeyInAttributes(child, e.changed.attributes)
          });
        });
      },
      defaults: {
        type: "default",
        traits(component) {

          let offerData = data.map(o => {
            return {
              id: o.UniqueToken,
              name: o.FriendlyName ? `${o.FriendlyName} (${o.TakenOfferName})` : o.TakenOfferName
            };
          }).sort((a, b) => {
            return String(a.name).localeCompare(b.name);
          });
          offerData = [{ id: null, name: "Not selected" }, ...offerData];
          return [
            {
              type: 'select',
              label: 'Select an offer',
              name: 'data-offers',
              options: offerData,
            },
            {
              name: "country", type: "select", options: [
                { id: "custom", name: "Custom" },
                { id: "ba", name: "Bosna", selected: true },
                { id: "rs", name: "Srbija" },
                { id: "hr", name: "Hrvatska" },
                { id: "me", name: "Crna Gora" },
                { id: "es", name: "Španija" }
              ], value: "ba"
            },
            { name: "data-suffix", label: "Lead UID (Name suffix in CRM)", placeholder: "CRM, LV...", value: "" },
          ]
        },
        attributes: {
          class: "open-form6 scroll-target"
        },
        script,
        styles: `
          .open-form6{
            box-shadow: 0 0 10px;
            border: 3px solid red;
            padding: 20px 10px;
            border-radius: 10px;
            text-align: center;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
          }
          .open-form6-headline{
            text-align: center;
            font-size: 21px;
            font-weight: 700;
            line-height: 1.4;
            margin-bottom: 15px;
          }
          .open-form6-generalText{
            text-align: center;
            font-size: 21px;
            line-height: 1.4;
            margin-bottom: 15px;
          }
          .open-form6-mashedText{
            text-align: center;
            font-size: 21px;
            line-height: 1.4;
            margin-bottom: 15px;
          }
          .open-form6-mashedText-decorated{
            color: #a21e2b;
            text-decoration: underline;
            display: inline-block;
            margin-left: 5px;
            margin-right: 5px;
          }
          .open-form6-img{
            width: 100%;
            max-width: 220px;
            height: auto;
          }
          .open-form6-pricing{
            display: flex;
            align-items: center;
            gap: 10px;
          }
          .open-form6-pricing-oldPrice{
            color: #4e4e4e;
            text-decoration: line-through;
            font-size: 20px;
            font-family: 'PT Sans', sans-serif;
          }
          .open-form6-pricing-newPrice{
            line-height: 1.5;
            color: #2e2e2e;
            font-weight: bold;
            font-size: 24px;
            padding-left: 20px;
          }
          .open-form6-dostava{
            text-align: center;
            font-weight: bold;
            color: #ff0000;
            font-size: 21px;
            line-height: 1.4;
            margin-bottom: 15px;
            margin-top: 10px;
          }
          
          

          

          .open-form6 .scalecrm-form{
            width: 100%;
            max-width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 30px;
            background: #a36a34;
          }
          .open-form6 .scalecrm-form > label{
            max-width: 100%;
            margin-bottom: 5px;
            font-weight: 700;
            font-family: 'PT Sans', sans-serif;
            color: #2e2e2e;
            text-rendering: optimizeLegibility;
            font-size: 18px;
          }
          .open-form6 .scalecrm-form > input{
            padding-left: 5px;
            height: 42px;
            margin-bottom: 10px;
            width: 300px !important;
            max-width: 100%;
            color: black;
          }
          .open-form6 .scalecrm-form > button{
            max-width: 100%;
            width: 300px;
            background-color: red;
            text-transform: uppercase;
            padding: 10px 15px;
            margin: 15px 0;
            border: none;
            margin-top: 5px;
            border-radius: 5px;
            box-shadow: 0px 4px 3px #242120;
            transition: 0.3s ease;
            color: #fff;
            font-weight: bold;
          }
          .open-form6 .scalecrm-form > button:hover{
            background: #bb0000;
          }

          .open-form6 .scalelead-form{
            width: 100%;
            max-width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 30px;
            background: #a36a34;
          }
          .open-form6 .scalelead-form > label{
            max-width: 100%;
            margin-bottom: 5px;
            font-weight: 700;
            font-family: 'PT Sans', sans-serif;
            color: #2e2e2e;
            text-rendering: optimizeLegibility;
            font-size: 18px;
          }
          .open-form6 .scalelead-form > input{
            padding-left: 5px;
            height: 42px;
            margin-bottom: 10px;
            width: 300px !important;
            max-width: 100%;
            color: black;
          }
          .open-form6 .scalelead-form > button{
            max-width: 100%;
            width: 300px;
            background-color: red;
            text-transform: uppercase;
            padding: 10px 15px;
            margin: 15px 0;
            border: none;
            margin-top: 5px;
            border-radius: 5px;
            box-shadow: 0px 4px 3px #242120;
            transition: 0.3s ease;
            color: #fff;
            font-weight: bold;
          }
          .open-form6 .scalelead-form > button:hover{
            background: #bb0000;
          }


          @media screen and (max-width: 700px){
            .open-form6{
            }
          }
        `,
        components: [
          
          {
            type: 'text',
            attributes: { class: 'open-form6-headline formInserted' },
            components: 'Pažnja'
          },
          {
            type: 'text',
            attributes: { class: 'open-form6-generalText' },
            components: 'Popust od 50% možete iskoristiti sad! Pored popusta dajemo Vam BESPLATNU dostavu! '
          },
          {
            type: 'image',
            attributes: { class: 'open-form6-img' },
          },
          {
            type: 'text',
            attributes: { class: 'open-form6-mashedText' },
            components: [
              'Dragi čitaoci! Pregovarali smo sa proizvođačima',
              {
                type: 'text',
                attributes: { for: "ProizvodName", class: 'open-form6-mashedText-decorated' },
                components: 'PROIZVODA'
              },
              'i pružamo jedinstvenu priliku da ga može naručiti i svako ko želi ovaj zajednički proizvod. Prvih 100 kupaca će dobiti hemoren sa popustom od 50%. Da biste to uradili, potrebno je da popunite poseban obrazac: '
            ]
          },
          {
            type: 'text',
            attributes: { class: 'open-form6-pricing' },
            components: [
              {
                type: 'text',
                attributes: { for: "OldPrice", class: 'open-form6-pricing-oldPrice' },
                components: '7200 RSD'
              },
              {
                type: 'text',
                attributes: { class: 'open-form6-pricing-newPrice' },
                components: 'BESPLATNO'
              },
            ]
          },
          {
            type: 'text',
            attributes: { class: 'open-form6-dostava' },
            components: 'BESPLATNA DOSTAVA'
          },
          
          {
            type: 'scalelead-form_cmp',
            attributes: { class: 'scalelead-form', 
              'data-token': '', 
              'data-tokenselect': '', 
              'data-domain': '' ,
              'data-article': '',
              'language': '',
              'data-custombuttontext': ''
            }
          }
        ]
      }
    }
  });


  editor.BlockManager.add('open-form6_blk', {
    label: 'Open form 6',
    content: {
      type: "open-form6_cmp",
    },
    category: {
      label: 'Open Forms'
    },
    media: "<img src='/images/openForm6.svg' style='width: 100%; height: auto; filter: invert(1) hue-rotate(180deg);' />",
    attributes: {
      title: 'Insert an Open Form block',
    },
    select: true
  });
};