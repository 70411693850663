import gjs from "grapesjs";

function script() {

  let scaleLeadForm = document.querySelector(".scalelead-form");

  eval(``)
};

/**
 * @param {gjs.Editor} editor
 */
export default (editor, data, trackData) => {

  editor.on('component:add', (component) => {
    // Check if there are existing components with the class 'formInserted'
    const existingForms = editor.Canvas.getDocument().querySelectorAll('.formInserted');
  
    if (existingForms.length > 1) {
      // Remove the newly added component from the editor
      component.remove();
  
      console.log("Existing forms found: ", existingForms.length);
    }
  });

  const findComponentByType = (components, type) => {
    for (let component of components) {
      if (component.get('type') === type) {
        return component;
      }
      if (component.models && component.models.length > 0) {
        let nestedComponent = findComponentByType(component.components, type);
        if (nestedComponent) {
          return nestedComponent;
        }
      }else{
        if(typeof(component.components) === "function"){
          let nestedComponent = findComponentByType(component.components(), type);
          if (nestedComponent) {
            return nestedComponent;
          }
        }
      }
    }
    return null;
  }

  function findForKeyInAttributes(object, changed) {
    // console.log(inner, "OBJECTS ARRAY --- ", object)
    if(!changed) return

    if (object?.attributes && object?.attributes?.for || object?.attributes && object?.attributes?.attributes?.for) {
      switch (object?.attributes?.attributes?.for) {
        case 'ProizvodName':
          let currElem = data.find(el => el.UniqueToken === changed["data-offers"]);
          if(!currElem) break;
          let trackElem = trackData.find(el => el.ScaleleadOfferID === currElem.BoundOffer);
          if(!trackElem) break;
          object.components(`${trackElem.OfferName}`);
          break;
        case 'OldPrice':
          switch (changed?.country) {
            case "rs":
              object.components('7200 RSD');
              return;
            case "hr":
              object.components('79.80 €');
              return;
            case "ba":
              object.components('119.80 KM');
              return;
            case "me":
              object.components('79.80 €');
              return;
            case "es":
              object.components('79.80 €');
              return;
            default:
              break;
          };
          break;
      
        default:
          break;
      }
    }

    if (object?.attributes?.components?.models) {
      object?.attributes?.components?.models.forEach(elem => {
        findForKeyInAttributes(elem, changed)
      })
    }
  }

  editor.Components.addType('open-form5_cmp', {
    isComponent: e => e?.classList?.contains("open-form5"),
    model: {
      init() {
        this.on("change", e => {
          let formComponent = findComponentByType(editor.DomComponents.getComponents(), 'scalelead-form_cmp');
          if (e?.attributes?.attributes?.["data-offers"]) {
            formComponent.attributes.attributes["data-tokenselect"] = e?.attributes?.attributes?.["data-offers"];
            let curOffer = data.find(o => o.UniqueToken === e?.attributes?.attributes?.["data-offers"]);
            if (curOffer) {
              formComponent.attributes.attributes["data-domain"] = curOffer.Domain;
            }
          }

          formComponent.attributes.attributes["data-tokenselect"] = e?.attributes?.attributes?.["data-offers"];
          formComponent.attributes.attributes["data-article"] = e?.attributes?.attributes?.["data-suffix"];

          this.get("components").each(child => {
            findForKeyInAttributes(child, e.changed.attributes)
          });
        });
      },
      defaults: {
        type: "default",
        traits(component) {

          let offerData = data.map(o => {
            return {
              id: o.UniqueToken,
              name: o.FriendlyName ? `${o.FriendlyName} (${o.TakenOfferName})` : o.TakenOfferName
            };
          }).sort((a, b) => {
            return String(a.name).localeCompare(b.name);
          });
          offerData = [{ id: null, name: "Not selected" }, ...offerData];
          return [
            {
              type: 'select',
              label: 'Select an offer',
              name: 'data-offers',
              options: offerData,
            },
            {
              name: "country", type: "select", options: [
                { id: "custom", name: "Custom" },
                { id: "ba", name: "Bosna", selected: true },
                { id: "rs", name: "Srbija" },
                { id: "hr", name: "Hrvatska" },
                { id: "me", name: "Crna Gora" },
                { id: "es", name: "Španija" }
              ], value: "ba"
            },
            { name: "data-suffix", label: "Lead UID (Name suffix in CRM)", placeholder: "CRM, LV...", value: "" },
          ]
        },
        attributes: {
          class: "open-form5 scroll-target"
        },
        script,
        styles: `
          .open-form5{
            max-width: 1000px;
            width: 100%;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            box-shadow: 0 0 10px;
            padding: 20px 10px;
            border-radius: 10px;
          }
          .open-form5-headline{
            font-weight: 700;
            text-align: center;
            margin-bottom: 15px;
            font-size: 22px !important;
            line-height: 26px !important;
            font-family: Georgia, Times, 'Times New Roman', serif;
          }
          .open-form5-img{
            width: 100%;
            max-width: 600px;
            margin: 0 auto;
            height: auto;
          }
          .open-form5-oldPrice{
            font-size: 20px;
            text-decoration: line-through;
            display: block;
            text-align: center;
            font-weight: 700;
            font-family: Georgia, Times, 'Times New Roman', serif;
          }
          .open-form5-newPrice{
            font-size: 28px;
            color: green;
            display: block;
            text-align: center;
            font-weight: 700;
            font-family: Georgia, Times, 'Times New Roman', serif;
          }
          

          .open-form5 .scalecrm-form{
            width: 100%;
            padding: 0px;
            max-width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 20px;
          }
          .open-form5 .scalecrm-form > label{
            margin-bottom: 10px;
          }
          .open-form5 .scalecrm-form > input{
            margin-bottom: 20px;
            width: 80%;
            padding: 22px 0;
            box-shadow: 0 1px 3px rgba(51,51,51,.35);
            border: none;
            border-radius: 4px;
            text-indent: 20px;
            font-size: 20px;
            color: #464646;
            background: #fff;
          }
          .open-form5 .scalecrm-form > button{
            border: none;
            font-size: 1.4em;
            font-weight: bold;
            width: 80%;
            cursor: pointer;
            border-radius: 10px;
            color: #fff !important;
            letter-spacing: 1px;
            display: flex;
            padding: 20px 20px !important;
            text-transform: uppercase;
            background-color: #4cad0e;
            justify-content: center;
            align-items: center;
            transition: 0.3s ease;
          }
          .open-form5 .scalecrm-form > button:hover{
            background-color: #74d437;
          }

          .open-form5 .scalelead-form{
            width: 100%;
            padding: 0px;
            max-width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 20px;
          }
          .open-form5 .scalelead-form > label{
            margin-bottom: 10px;
          }
          .open-form5 .scalelead-form > input{
            margin-bottom: 20px;
            width: 80%;
            padding: 22px 0;
            box-shadow: 0 1px 3px rgba(51,51,51,.35);
            border: none;
            border-radius: 4px;
            text-indent: 20px;
            font-size: 20px;
            color: #464646;
            background: #fff;
          }
          .open-form5 .scalelead-form > button{
            border: none;
            font-size: 1.4em;
            font-weight: bold;
            width: 80%;
            cursor: pointer;
            border-radius: 10px;
            color: #fff !important;
            letter-spacing: 1px;
            display: flex;
            padding: 20px 20px !important;
            text-transform: uppercase;
            background-color: #4cad0e;
            justify-content: center;
            align-items: center;
            transition: 0.3s ease;
          }
          .open-form5 .scalelead-form > button:hover{
            background-color: #74d437;
          }


          @media screen and (max-width: 700px){
            .open-form5{
            }
          }
        `,
        components: [
          
          {
            type: 'text',
            attributes: { class: 'open-form5-headline formInserted' },
            components: 'Požurite da izvršite vašu narudžbu pre nego što popust pređe na sledećeg čitaoca, vaša jedinstvena ponuda završava za:'
          },
          {
            type: 'image',
            attributes: { class: 'open-form5-img' },
          },
          {
            type: 'text',
            attributes: { for: "OldPrice", class: 'open-form5-oldPrice' },
            components: '7200 RSD'
          },
          {
            type: 'text',
            attributes: { class: 'open-form5-newPrice' },
            components: 'BESPLATNO'
          },
          {
            type: 'scalelead-form_cmp',
            attributes: { class: 'scalelead-form', 
              'data-token': '', 
              'data-tokenselect': '', 
              'data-domain': '' ,
              'data-article': '',
              'language': '',
              'data-custombuttontext': ''
            }
          }

        ]
      }
    }
  });


  editor.BlockManager.add('open-form5_blk', {
    label: 'Open form 5',
    content: {
      type: "open-form5_cmp",
    },
    category: {
      label: 'Open Forms'
    },
    media: "<img src='/images/openForm5.svg' style='width: 100%; height: auto; filter: invert(1) hue-rotate(180deg);' />",
    attributes: {
      title: 'Insert an Open Form block',
    },
    select: true
  });
};