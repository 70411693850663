import gjs from "grapesjs";

function script() {

  let scaleLeadForm = document.querySelector(".scalelead-form");

  eval(``)
};

/**
 * @param {gjs.Editor} editor
 */
export default (editor, data, trackData) => {

  editor.on('component:add', (component) => {
    // Check if there are existing components with the class 'formInserted'
    const existingForms = editor.Canvas.getDocument().querySelectorAll('.formInserted');
  
    if (existingForms.length > 1) {
      // Remove the newly added component from the editor
      component.remove();
  
      console.log("Existing forms found: ", existingForms.length);
    }
  });

  const findComponentByType = (components, type) => {
    for (let component of components) {
      if (component.get('type') === type) {
        return component;
      }
      if (component.models && component.models.length > 0) {
        let nestedComponent = findComponentByType(component.components, type);
        if (nestedComponent) {
          return nestedComponent;
        }
      }else{
        if(typeof(component.components) === "function"){
          let nestedComponent = findComponentByType(component.components(), type);
          if (nestedComponent) {
            return nestedComponent;
          }
        }
      }
    }
    return null;
  }

  function findForKeyInAttributes(object, changed) {
    // console.log(inner, "OBJECTS ARRAY --- ", object)
    if(!changed) return

    if (object?.attributes && object?.attributes?.for || object?.attributes && object?.attributes?.attributes?.for) {
      switch (object?.attributes?.attributes?.for) {
        case 'ProizvodName':
          let currElem = data.find(el => el.UniqueToken === changed["data-offers"]);
          if(!currElem) break;
          let trackElem = trackData.find(el => el.ScaleleadOfferID === currElem.BoundOffer);
          if(!trackElem) break;
          object.components(`${trackElem.OfferName}`);
          break;
        case 'OldPrice':
          switch (changed?.country) {
            case "rs":
              object.components('7200 RSD');
              return;
            case "hr":
              object.components('79.80 €');
              return;
            case "ba":
              object.components('119.80 KM');
              return;
            case "me":
              object.components('79.80 €');
              return;
            case "es":
              object.components('79.80 €');
              return;
            default:
              break;
          };
          break;
      
        default:
          break;
      }
    }

    if (object?.attributes?.components?.models) {
      object?.attributes?.components?.models.forEach(elem => {
        findForKeyInAttributes(elem, changed)
      })
    }
  }


  editor.Components.addType('open-form2_cmp', {
    isComponent: e => e?.classList?.contains("open-form2"),
    model: {
      init() {
        this.on("change", e => {
          let formComponent = findComponentByType(editor.DomComponents.getComponents(), 'scalelead-form_cmp');
          if (e?.attributes?.attributes?.["data-offers"]) {
            formComponent.attributes.attributes["data-tokenselect"] = e?.attributes?.attributes?.["data-offers"];
            let curOffer = data.find(o => o.UniqueToken === e?.attributes?.attributes?.["data-offers"]);
            if (curOffer) {
              formComponent.attributes.attributes["data-domain"] = curOffer.Domain;
            }
          }

          formComponent.attributes.attributes["data-tokenselect"] = e?.attributes?.attributes?.["data-offers"];
          formComponent.attributes.attributes["data-article"] = e?.attributes?.attributes?.["data-suffix"];

          this.get("components").each(child => {
            findForKeyInAttributes(child, e.changed.attributes)
          });
        });
      },
      defaults: {
        type: "default",
        traits(component) {

          let offerData = data.map(o => {
            return {
              id: o.UniqueToken,
              name: o.FriendlyName ? `${o.FriendlyName} (${o.TakenOfferName})` : o.TakenOfferName
            };
          }).sort((a, b) => {
            return String(a.name).localeCompare(b.name);
          });
          offerData = [{ id: null, name: "Not selected" }, ...offerData];
          return [
            {
              type: 'select',
              label: 'Select an offer',
              name: 'data-offers',
              options: offerData,
            },
            {
              name: "country", type: "select", options: [
                { id: "custom", name: "Custom" },
                { id: "ba", name: "Bosna", selected: true },
                { id: "rs", name: "Srbija" },
                { id: "hr", name: "Hrvatska" },
                { id: "me", name: "Crna Gora" },
                { id: "es", name: "Španija" }
              ], value: "ba"
            },
            { name: "data-suffix", label: "Lead UID (Name suffix in CRM)", placeholder: "CRM, LV...", value: "" },
          ]
        },
        attributes: {
          class: "open-form2 scroll-target"
        },
        script,
        styles: `
          .open-form2{
            width: 100%;
            max-width: 1140px;
            margin: 0 auto;
            margin-bottom: 40px;
            background-color: #ebebeb;
            position: relative;
            padding: 32px 16px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            height: auto;
            max-height: 520px;
          }
          .open-form2-text{
            z-index: 2;
          }
          .open-form2-text-head{
            font-weight: bold;
            margin-top: 5%;
            margin-bottom: 5%;
            text-transform: uppercase;
            font-size: 37px;
            line-height: 1.2;
            color: #212529;
          }
          .open-form2-text-msg{
            margin-bottom: 8px;
            font-family: "Source Sans 3", sans-serif;
            font-size: 16px;
            line-height: 1.5;
            color: #212529;
          }
          .open-form2-text-deco{
            font-size: 26px;
            font-family: 'Lobster', cursive;
            margin-top: 80px;
            max-width: 340px;
            font-weight: bold;
            color: #fff;
            line-height: 1.5;
            z-index: 2;
          }

          .open-form2-form{
            position: relative;
            bottom: -10px;
            border: 1px solid #fdcb08;
            border-radius: .25rem;
            background-color: #fdcb08;
            padding: 1rem;
            font-family: "Source Sans 3", sans-serif;
            font-size: 16px;
            line-height: 1.5;
            color: #212529;
            z-index: 3;
          }
          .open-form2-form-head{
            margin-bottom: 1rem;
            text-align: center;
            padding-top: 1rem;
            font-weight: 700;
            font-family: "Source Sans 3", sans-serif;
            font-size: 16px;
            line-height: 1.5;
            color: #212529;
          }
          .open-form2-form-oldPrice{
            display: block;
            font-weight: 700;
            text-decoration: line-through;
            text-align: center;
            font-size: 28px;
            line-height: 1.2;
            margin-bottom: 8px;
            color: #212529;
            font-family: "Source Sans 3", sans-serif;
          }
          .open-form2-form-newPrice{
            display: block;
            text-align: center;
            font-weight: 700;
            color: #fff;
            font-size: 37px;
            line-height: 1.2;
          }

          .open-form2-img{
            width: 100%;
            max-width: 237px;
            height: auto;
            z-index: 3;
            margin: 0 auto;
            margin-top: auto;
          }

          .open-form2-banner{
            width: 104%;
            position: absolute;
            bottom: 15px;
            left: -2%;
            z-index: 1;
            font-family: "Source Sans 3", sans-serif;
            font-size: 16px;
            line-height: 1.5;
            color: #212529;
            background-color: orange;
            height: 200px;
            transform: rotate(-2deg);
          }

          .open-form2 .scalecrm-form{
            width: 100%;
          }
          .open-form2 .scalecrm-form > label{
            margin-bottom: 10px;
            font-family: "Source Sans 3", sans-serif;
            font-size: 16px;
            line-height: 1.5;
            color: #212529;
          }
          .open-form2 .scalecrm-form > input{
            padding-left: 5px;
            height: 42px;
            width: 100%;
            border: 2px solid grey;
            border-radius: 5px;
            font-size: 19px;
            outline: none;
          }
          .open-form2 .scalecrm-form > button{
            transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 700;
            cursor: pointer;
            margin-top: 15px;
            padding: 6px 12px;
            border-radius: 20px;
            font-size: 27px;
            line-height: 1.5;
            color: #fff;
            background-color: #28a745;
            border: 1px solid #28a745;
            font-family: "Source Sans 3", sans-serif;
          }
          .open-form2 .scalecrm-form > button:hover{
            color: #fff;
            background-color: #218838;
            border-color: #1e7e34;
          }

          .open-form2 .scalelead-form{
            width: 100%;
          }
          .open-form .scalelead-form > label{
            margin-bottom: 10px;
            font-family: "Source Sans 3", sans-serif;
            font-size: 16px;
            line-height: 1.5;
            color: #212529;
          }
          .open-form2 .scalelead-form > input{
            padding-left: 5px;
            height: 42px;
            width: 100%;
            border: 2px solid grey;
            border-radius: 5px;
            font-size: 19px;
            outline: none;
          }
          .open-form2 .scalelead-form > button{
            transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 700;
            cursor: pointer;
            margin-top: 15px;
            padding: 6px 12px;
            border-radius: 20px;
            font-size: 27px;
            line-height: 1.5;
            color: #fff;
            background-color: #28a745;
            border: 1px solid #28a745;
            font-family: "Source Sans 3", sans-serif;
          }
          .open-form2 .scalelead-form > button:hover{
            color: #fff;
            background-color: #218838;
            border-color: #1e7e34;
          }

          @media screen and (max-width: 854px){
            .open-form2-text-deco{
              margin-top: 0px;
            }
          }
          @media screen and (max-width: 676px){
            .open-form2{
              display: flex;
              flex-direction: column;
              max-height: unset;
            }
            .open-form2-text-head{
              margin-top: 0px;
              margin-bottom: 16px;
            }
            .open-form2-text-deco{
              margin-top: 0px;
              width: 100%;
              max-width: 600px;
              text-align: center;
              color: #000;
            }
          }
        `,
        components: [
          {
            tagName: 'div',
            attributes: { class: 'open-form2-text formInserted' },
            components: [
              {
                type: 'text',
                attributes: { class: 'open-form2-text-head' },
                components: 'PRIRODNI STIMULANS ZA IMUNI SISTEM!'
              },
              {
                type: 'text',
                attributes: { class: 'open-form2-text-msg' },
                components: [
                  {
                    type: 'text',
                    attributes: { class: '' },
                    components: 'Obratite pažnju na svoje zdravlje. Isprobajte '
                  },
                  {
                    type: 'text',
                    attributes: { for: "ProizvodName", class: 'proizvod-name' },
                    components: 'PRODUKT '
                  },
                  {
                    type: 'text',
                    attributes: { class: '' },
                    components: 'i ojačajte svoj imuni sistem. '
                  },
                ]
              },
              {
                type: 'text',
                attributes: { class: 'open-form2-text-deco' },
                components: [
                  {
                    type: 'text',
                    attributes: { class: '' },
                    components: 'Ograničena ponuda - Preuzmite '
                  },
                  {
                    type: 'text',
                    attributes: { for: "ProizvodName", class: 'proizvod-name' },
                    components: 'PRODUKT '
                  },
                  {
                    type: 'text',
                    attributes: { class: '' },
                    components: 'POTPUNO BESPLATNO! '
                  },
                ]
              },
            ]
          },
          {
            tagName: 'div',
            attributes: { class: 'open-form2-form' },
            components: [
              {
                type: 'text',
                attributes: { class: 'open-form2-form-head' },
                components: 'Najbolja ponuda'
              },
              {
                type: 'text',
                attributes: { for: "OldPrice", class: 'open-form2-form-oldPrice' },
                components: '3600 RSD'
              },
              {
                type: 'text',
                attributes: { class: 'open-form2-form-newPrice' },
                components: 'BESPLATNO'
              },
              {
                type: 'scalelead-form_cmp',
                attributes: { class: 'scalelead-form', 
                  'data-token': '', 
                  'data-tokenselect': '', 
                  'data-domain': '' ,
                  'data-article': '',
                  'language': '',
                  'data-custombuttontext': ''
                }
              }
            ]
          },
          {
            type: 'image',
            attributes: { class: 'open-form2-img' },
          },
          {
            tagName: 'div',
            attributes: { class: 'open-form2-banner' },
            components: [
            ]
          }
        ]
      }
    }
  });


  editor.BlockManager.add('open-form2_blk', {
    label: 'Open form 2',
    content: {
      type: "open-form2_cmp",
    },
    category: {
      label: 'Open Forms'
    },
    media: "<img src='/images/openForm2.svg' style='width: 100%; height: auto; filter: invert(1) hue-rotate(180deg);' />",
    attributes: {
      title: 'Insert an Open Form block',
    },
    select: true
  });
};