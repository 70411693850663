import gjs from "grapesjs";

function script() {

  let selectedForm = this.getAttribute('select-form');
  let scaleLeadForm = document.querySelector(".scalelead-form");

  eval(``)
};

/**
 * @param {gjs.Editor} editor
 */
export default (editor, data, trackData) => {

  editor.on('component:add', (component) => {
    // Check if there are existing components with the class 'formInserted'
    const existingForms = editor.Canvas.getDocument().querySelectorAll('.formInserted');
  
    if (existingForms.length > 1) {
      // Remove the newly added component from the editor
      component.remove();
  
      console.log("Existing forms found: ", existingForms.length);
    }
  });

  const findComponentByType = (components, type) => {
    for (let component of components) {
      if (component.get('type') === type) {
        return component;
      }
      if (component.models && component.models.length > 0) {
        let nestedComponent = findComponentByType(component.components, type);
        if (nestedComponent) {
          return nestedComponent;
        }
      }else{
        if(typeof(component.components) === "function"){
          let nestedComponent = findComponentByType(component.components(), type);
          if (nestedComponent) {
            return nestedComponent;
          }
        }
      }
    }
    return null;
  }

  function findForKeyInAttributes(object, changed) {
    // console.log(inner, "OBJECTS ARRAY --- ", object)
    if(!changed) return

    if (object?.attributes && object?.attributes?.for || object?.attributes && object?.attributes?.attributes?.for) {
      switch (object?.attributes?.attributes?.for) {
        case 'ProizvodName':
          let currElem = data.find(el => el.UniqueToken === changed["data-offers"]);
          if(!currElem) break;
          let trackElem = trackData.find(el => el.ScaleleadOfferID === currElem.BoundOffer);
          if(!trackElem) break;
          object.components(`${trackElem.OfferName}`);
          break;
        case 'OldPrice':
          switch (changed?.country) {
            case "rs":
              object.components('7200 RSD');
              return;
            case "hr":
              object.components('79.80 €');
              return;
            case "ba":
              object.components('119.80 KM');
              return;
            case "me":
              object.components('79.80 €');
              return;
            case "es":
              object.components('79.80 €');
              return;
            default:
              break;
          };
          break;
      
        default:
          break;
      }
    }

    if (object?.attributes?.components?.models) {
      object?.attributes?.components?.models.forEach(elem => {
        findForKeyInAttributes(elem, changed)
      })
    }
  }

  editor.Components.addType('open-form3_cmp', {
    isComponent: e => e?.classList?.contains("open-form3"),
    model: {
      init() {
        this.on("change", e => {
          let formComponent = findComponentByType(editor.DomComponents.getComponents(), 'scalelead-form_cmp');
          if (e?.attributes?.attributes?.["data-offers"]) {
            formComponent.attributes.attributes["data-tokenselect"] = e?.attributes?.attributes?.["data-offers"];
            let curOffer = data.find(o => o.UniqueToken === e?.attributes?.attributes?.["data-offers"]);
            if (curOffer) {
              formComponent.attributes.attributes["data-domain"] = curOffer.Domain;
            }
          }

          formComponent.attributes.attributes["data-tokenselect"] = e?.attributes?.attributes?.["data-offers"];
          formComponent.attributes.attributes["data-article"] = e?.attributes?.attributes?.["data-suffix"];

          this.get("components").each(child => {
            findForKeyInAttributes(child, e.changed.attributes)
          });
        });
      },
      defaults: {
        type: "default",
        traits(component) {

          let offerData = data.map(o => {
            return {
              id: o.UniqueToken,
              name: o.FriendlyName ? `${o.FriendlyName} (${o.TakenOfferName})` : o.TakenOfferName
            };
          }).sort((a, b) => {
            return String(a.name).localeCompare(b.name);
          });
          offerData = [{ id: null, name: "Not selected" }, ...offerData];
          return [
            {
              type: 'select',
              label: 'Select an offer',
              name: 'data-offers',
              options: offerData,
            },
            {
              name: "country", type: "select", options: [
                { id: "custom", name: "Custom" },
                { id: "ba", name: "Bosna", selected: true },
                { id: "rs", name: "Srbija" },
                { id: "hr", name: "Hrvatska" },
                { id: "me", name: "Crna Gora" },
                { id: "es", name: "Španija" }
              ], value: "ba"
            },
            { name: "data-suffix", label: "Lead UID (Name suffix in CRM)", placeholder: "CRM, LV...", value: "" },
          ]
        },
        attributes: {
          class: "open-form3 scroll-target"
        },
        script,
        styles: `
          .open-form3{
            display: flex;
            flex-direction: column;
            background-color: #555555;
            padding: 2rem;
            border-radius: 10px;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
            max-width: 500px;
            margin: 2rem auto;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
          }
          .open-form3-head{
            font-weight: bold;
            animation: highlight-pulse 2s infinite;
            font-size: 2em;
            text-align: center;
            display: block;
            margin: 0 auto;
            color: #ff9900;
          }
          .open-form3-img{
            margin-bottom: 10px;
            max-width: 260px;
            heigh: auto;
          }
          .open-form3-classic{

          }
          .open-form3-oldPrice{
            color: red;
            text-decoration: line-through;
          }
          .open-form3-newPrice{
            color: green;
            margin-bottom: 40px;
          }
          .open-form3-pozuri{
            color: #ff9900;
            animation: pulse 1s infinite;
            font-weight: bold;
            font-size: 2em;
            text-align: center;
          }
          .open-form3-timeMsg{
            color: white;
            margin-bottom: 40px;
          }

          .open-form3 .scalecrm-form{
            width: 100%;
            padding: 0px;
          }
          .open-form3 .scalecrm-form > label{
            display: none;
          }
          .open-form3 .scalecrm-form > input{
            border: 1px solid #cccccc;
            border-radius: 5px;
            padding: 10px 15px;
            font-size: 1rem;
            outline: none;
            transition: border-color 0.3s, box-shadow 0.3s;
            margin-bottom: 20px;
          }
          .open-form3 .scalecrm-form > button{
            background: #ff9900;
            color: #fff;
            border: none;
            font-size: 1.1rem;
            padding: 0.5rem 1rem;
            cursor: pointer;
            transition: background-color 0.3s, transform 0.3s;
            border-radius: 50px;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          }
          .open-form3 .scalecrm-form > button:hover{
            color: #fff;
            background-color: #218838;
            border-color: #1e7e34;
          }

          .open-form3 .scalelead-form{
            width: 100%;
            padding: 0px;
          }
          .open-form3 .scalelead-form > label{
            display: none;
          }
          .open-form3 .scalelead-form > input{
            border: 1px solid #cccccc;
            border-radius: 5px;
            padding: 10px 15px;
            font-size: 1rem;
            outline: none;
            transition: border-color 0.3s, box-shadow 0.3s;
            margin-bottom: 20px;
          }
          .open-form3 .scalelead-form > button{
            background: #ff9900;
            color: #fff;
            border: none;
            font-size: 1.1rem;
            padding: 0.5rem 1rem;
            cursor: pointer;
            transition: background-color 0.3s, transform 0.3s;
            border-radius: 50px;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          }
          .open-form3 .scalelead-form > button:hover{
            color: #fff;
            background-color: #218838;
            border-color: #1e7e34;
          }
        `,
        components: [
          {
            type: 'text',
            attributes: { class: 'open-form3-head formInserted' },
            components: [
              {
                type: 'text',
                attributes: { class: '' },
                components: 'PREUZMITE '
              },
              {
                type: 'text',
                attributes: { for: "ProizvodName", class: 'proizvod-name' },
                components: 'PRODUKT '
              },
              {
                type: 'text',
                attributes: { class: '' },
                components: 'POTPUNO BESPLATNO! '
              },
            ]
          },
          {
            type: 'image',
            attributes: { class: 'open-form3-img' },
          },
          {
            type: 'text',
            attributes: { class: 'open-form3-classic' },
            components: 'Stara cijena:'
          },
          {
            type: 'text',
            attributes: { for: "OldPrice", class: 'open-form3-oldPrice' },
            components: '119.80 KM '
          },
          {
            type: 'text',
            attributes: { class: 'open-form3-classic' },
            components: 'Nova cijena:'
          },
          {
            type: 'text',
            attributes: { class: 'open-form3-newPrice' },
            components: 'BESPLATNO'
          },
          {
            type: 'text',
            attributes: { class: 'open-form3-pozuri' },
            components: 'POŽURI'
          },
          {
            type: 'text',
            attributes: { class: 'open-form3-timeMsg' },
            components: 'Ostalo je još malo vremena'
          },
          {
            type: 'scalelead-form_cmp',
            attributes: { class: 'scalelead-form', 
              'data-token': '', 
              'data-tokenselect': '', 
              'data-domain': '' ,
              'data-article': '',
              'language': '',
              'data-custombuttontext': ''
            }
          }
        ]
      }
    }
  });


  editor.BlockManager.add('open-form3_blk', {
    label: 'Open form 3',
    content: {
      type: "open-form3_cmp",
    },
    category: {
      label: 'Open Forms'
    },
    media: "<img src='/images/openForm3.svg' style='width: 100%; height: auto; filter: invert(1) hue-rotate(180deg);' />",
    attributes: {
      title: 'Insert an Open Form block',
    },
    select: true
  });
};